
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import PdfManager from "@/components/PdfManager.vue";
import {ICandidateDB, IUserDB, StoreAction, CV} from "@/types";
import { Action, State } from 'vuex-class';
import {showModal} from '@/helpers/callables';
import ParseWait from '@/components/ats/modal/ParseWait.vue';
import { parsePhoneNumber } from 'libphonenumber-js';
import "vue-pdf-app/dist/icons/main.css";
import moment from 'moment';

@Component({
    name: "CV",
    components: {PdfManager, ParseWait}
})
export default class Cv extends Vue {
    @Prop() candidate!: ICandidateDB;
    @Prop() readonly !: boolean;

    apiUrl: string = <string>process.env.VUE_APP_API_URL?.replace('/1.0', '') + "/";
    showParseWait: boolean = false;

    @Watch('candidate.cv.path')
    onCV() {
        this.$emit('update');
    }

    @Action('actions/editCandidate') saveCandidate!: StoreAction;
    @Action('actions/analyseCandidate') analyseCandidate!: StoreAction;

    @State('currentUser') currentUser!: IUserDB;

    get isNotParsedCV() {
        if(!this.candidate.cv.path) return false;
        if(!this.candidate?.dataAnalysis) return true;
        return Object.keys(this.candidate.dataAnalysis).length === 0;
    }

    async analysedCV() {
        try {
            this.openModal();

            const parsedCandidate = await this.analyseCandidate({
                data: {
                    cv: this.candidate.cv.path,
                }
            });

            this.candidate.dataAnalysis = parsedCandidate.raw;

            this.candidate.firstname = parsedCandidate.parsed.firstname?.value || '';
            this.candidate.lastname = parsedCandidate.parsed.lastname?.value || '';
            this.candidate.email = parsedCandidate.parsed.email?.value || '';
            this.candidate.picture = parsedCandidate.parsed.picture?.value || '';
            this.candidate.phone = parsedCandidate.parsed.phone.value ? parsePhoneNumber(parsedCandidate.parsed.phone.value, 'FR').formatNational() : '';
            this.candidate.address = {
                name: "",
                street: parsedCandidate.parsed.address.street.value || '',
                complement: "",
                city: parsedCandidate.parsed.address.city.value || '',
                citySlug: "",
                zip: parsedCandidate.parsed.address.zip.value || '',
                countryCode: "",
            },
            parsedCandidate.parsed.address.value;

            this.closeModal();
            this.$nextTick( () => {
                this.$emit('changeTab', 1);
            });
        } catch(e) {
            console.log(e);
                // @ts-ignore;
                this.$toast.open({
                    message: this.$t('page.candidate.parsing.analyseError'),
                    type: "error",
                    duration: 10000
                });
                this.closeModal();
                throw e;
        }
    }

    openModal(){
        this.showParseWait = true;
        showModal.call(this, 'ParseWait');
    }

    closeModal(){
        this.showParseWait = false;
        this.$modal.hide('ParseWait');
    }

    async saveCv(cv: any) {
        this.candidate.dataAnalysis = {};
        if (cv) {
            const newCV: CV = {
                path: cv,
                collaboratorId: this.currentUser._id,
                registrationDate: moment().valueOf()
            }
            this.candidate.cv = newCV;
        } else {
            const newCV: CV = {
                path: ""
            }
            this.candidate.cv = newCV;
        }
        try {
            await this.saveCandidate(this.candidate);
            this.$toast.open({
                message: <string>this.$t('page.profile.saved'),
                type: "success",
                duration: 2000
            });
        } catch(e) {
            console.log(e);
            this.$toast.open({
                message: <string>this.$t('global.serverError'),
                type: "error",
                duration: 5000
            });
        }
    }

    beforeMount() {
    }
}
