
import {Component, Vue, Prop} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import VModel from "@/mixins/VModel.vue";

@Component({
    name: "DayUnavailabilities"
})
export default class DayUnavailabilities extends mixins(VModel) {
    @Prop() readonly label!: string;
    @Prop() readonly mode!: string;
    @Prop(Boolean) readonly directValue!: boolean;
    @Prop(Boolean) readonly reverse!: boolean;
    @Prop(Boolean) readonly readonly!: boolean;

    get dayMoment() {
        return ["morning", "afternoon", "evening", "night"];
    }

    check(context:string) {
        if (!this.readonly) {
            if (this.directValue) {
                this.innerValue[context] = !this.innerValue[context];
                this.$emit('checked', this.innerValue);
            } else {
                this.innerValue[context].available = !this.innerValue[context].available;
                this.$emit('checked', this.innerValue);
            }
        }
    }

    isAvailable(context:string) {
        if (this.directValue) {
            return this.reverse ? !this.innerValue[context] : this.innerValue[context];
        } else {
            return this.reverse ? !this.innerValue[context].available : this.innerValue[context].available;
        }
    }

    hasMission(context:string) {
        return this.innerValue[context]?.mission?._id;
    }
}
