
import {Component, Vue, Prop} from 'vue-property-decorator';
import {ICandidateDB, IWorkerJob, INationality, ICountry, IUserDB} from "@/types";
import CandidateAvailableCommands from "@/components/ats/Candidate/CandidateAvailableCommands.vue";
import {Getter, State} from "vuex-class";
import {capitalize, formattedAddress} from "@/helpers/commons";
import Avatar from '@/components/Avatar.vue';
import { parsePhoneNumber } from 'libphonenumber-js';
import moment from 'moment-timezone';

@Component({
    name: "CandidateRead",
    components: {CandidateAvailableCommands, Avatar}
})
export default class CandidateRead extends Vue {
    @Prop() candidate!: ICandidateDB;
    @Prop({default: false}) noCommand!: boolean;
    @Prop() errors:any ;

    // @ts-ignore;
    apiUrl:string = <string>process.env.VUE_APP_API_STATIC_URL + "/" ;
    moment = moment;
    parsePhoneNumber = parsePhoneNumber;

    @Getter('getJob') getJob!: any;
    @Getter('isSupervisor') isSupervisor!: boolean;
    @Getter('getCollaborator') getCollaborator!: any;
    @Getter('getNationalitiesOptions') getNationalitiesOptions!: Array<INationality>;
    @Getter('getBirthCountriesOptions') getBirthCountriesOptions!: Array<ICountry>;

    @State('currentUser') currentUser!: IUserDB;

    get picture() {
        if(this.candidate.picture?.startsWith('data:image')) {
            return this.candidate.picture;
        }
        if (this.candidate.picture?.startsWith("http")) {
            return this.candidate.picture;
        }
        return this.candidate.picture && (this.apiUrl + this.candidate.picture);
    }

    get fullName() {
        const firstname = capitalize(this.candidate.firstname) || '';
        const lastname = capitalize(this.candidate.lastname) || '';
        return `${firstname} ${lastname}`;
    }

    get positions() {
        return this.candidate.jobs?.map((j:IWorkerJob) => {
            if (j.job) return j.job.name;
            return this.getJob(j.jobId).name;
        }).join(', ') || "";
    }

    get phone() {
        return this.candidate.phone;
    }

    get email() {
        return this.candidate.email;
    }

    get address() {
        return [
            formattedAddress(this.candidate.address),
            this.candidate.address?.complement
        ].filter(e => e).join(', ');
    }

    get keywords() {
        return this.candidate.keywords?.map((k:string) => capitalize(k)) || [];
    }

    get birthDate() {
        return this.candidate.birthDate
    }

    get birthPlace() {
        return this.candidate.birthPlace;
    }

    get birthCountry() {
        return this.getBirthCountriesOptions.find((country: ICountry) => country.code === this.candidate.birthCountry)?.name || ''
    }

    get originOrSource() {
        return this.candidate.source || this.candidate.origin || '-';
    }

    get nationality() {
        return this.getNationalitiesOptions.find((nationality: INationality) => nationality.code === this.candidate.nationalityCode)?.name || ''
    }

    get status() {
        return this.candidate.currentAgency?.candidateStatus;
    }

    get cvImportedBy() {
        let c = this.getCollaborator(this.candidate.cv.collaboratorId);

        if (c) {
            return `${capitalize(c.firstname)} ${capitalize(c.lastname)}`;
        } else if (this.candidate.cv.collaboratorId === this.currentUser._id) {
            return `${capitalize(this.currentUser.firstname)} ${capitalize(this.currentUser.lastname)}`;
        }
    }
}
