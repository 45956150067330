
import { Component, Vue, Prop } from "vue-property-decorator";
import Tabs from "@/components/ats/layout/Tabs.vue";
import { ICandidateDB, StoreAction } from "@/types";
import { Action, Getter } from "vuex-class";
import { mixins } from "vue-class-component";
import TitleManager from "@/mixins/TitleManager.vue";
import { CandidateStatus, ROUTES } from "@/data";
import CandidateRead from "@/components/ats/Candidate/CandidateRead.vue";
import CV from "./CV.vue";
import Profile from "./Profile/Profile.vue";
import JobApplication from "./JobApplication.vue";
import Following from "./Following.vue";
import moment from "moment-timezone";
import CandidateLogger from "@/components/ats/Candidate/CandidateLogger.vue";

@Component({
    name: "CandidateLayout",
    components: { CandidateRead, Tabs, CV, Profile, JobApplication, Following, CandidateLogger },
})
export default class CandidateLayout extends mixins(TitleManager) {
    candidate: any = null;
    loading = false;
    routes: any = {};
    currentTabIndex = 0;
    isNewCandidate = false;

    moment = moment;

    @Action("actions/getCandidate") getCandidate!: StoreAction;
    @Action("actions/updateCandidate") updateCandidate!: StoreAction;
    @Action("actions/findDuplicated") findDuplicated!: StoreAction;

    @Getter("isSupervisor") isSupervisor!: boolean;
    @Getter("hasJobOffers") hasJobOffers!: boolean;

    componentsClass = [CV, Profile, JobApplication, CandidateLogger];

    get components() {
        return this.componentsClass.filter((component, componentIdx) => {
            return componentIdx === 2 && !this.hasJobOffers ? false : true;
        });
    }

    get isReadLayout() {
        return !!this.$route.matched.find((m: any) => m.name === ROUTES.ATS.CANDIDATE._ROOT);
    }

    get candidateId() {
        return this.$route.params.candidateId;
    }

    get currentTab() {
        return this.components[this.currentTabIndex];
    }

    get maskForV1() {
        return true;
    }

    routing() {
        const routes: any = (this.$t("page.candidates.navbar") as any).filter((route: any) =>
            route.label === "Candidatures" && !this.hasJobOffers ? false : true
        );

        return routes.map((r: any, index: number) => {
            const selected = index === this.currentTabIndex;
            const disable =
                (this.candidate?.status === CandidateStatus.integrated || this.candidate?.status === CandidateStatus.rejected || this.isSupervisor) &&
                r.route.canBeDisable;

            return {
                ...r,
                selected,
                disable,
            };
        });
    }

    changeTab(index: number) {
        this.routes[this.currentTabIndex].selected = false;
        this.routes[index].selected = true;
        this.currentTabIndex = index;
    }

    async forceRender() {
        this.loading = true;
        try {
            await this.receiveCandidate();
            this.$forceUpdate();
            this.loading = false;
        } catch (e) {
            console.log(e);
            this.loading = false;
        }
    }

    candidateUpdated(candidate: ICandidateDB) {
        this.candidate = candidate;
    }

    async update(data: any) {
        this.loading = true;
        try {
            this.candidate.cv = this.candidate.cv;
            this.candidate = await this.updateCandidate({
                candidateId: this.candidateId,
                data: {
                    cv: this.candidate.cv,
                    candidateParsed: data.candidateParsed,
                },
            });
        } catch (e) {
            console.log(e);
        }
        this.loading = false;
    }

    async receiveCandidate() {
        this.candidate = await this.getCandidate(this.candidateId);
        if (this.candidate?.birthDate) {
            this.candidate.birthDate = this.moment(this.candidate.birthDate).format(this.$t("date.format"));
        }
    }

    async init() {
        this.loading = true;
        try {
            await this.receiveCandidate();
            this.routes = this.routing();
            if (typeof this.$route?.query?.tabIndex === "string") this.changeTab(parseInt(this.$route.query.tabIndex));
        } catch (e) {
            console.log(e);
        }
        this.loading = false;
    }

    async checkIfDuplicatedUsers() {
        try {
            const duplidated = await this.findDuplicated({
                search: `${this.candidate.firstname} ${this.candidate.lastname}`,
            });
            if (duplidated.length === 0) return;

            // actions si on trouve un candidat dupliqué
        } catch (e) {
            console.log(e);
        }
    }

    beforeMount() {
        this.init();
        this.isNewCandidate = this.$route.query.new ? true : false;
        if (this.isNewCandidate) this.checkIfDuplicatedUsers();
    }
}
