
import {Component, Vue, Prop} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import VModel from "@/mixins/VModel.vue";
import DayUnavailabilities from "@/components/DayUnavailabilities2.vue";
import moment from "moment-timezone";
import {capitalize} from "@/helpers/commons";

@Component({
    name: "WeekUnavailabilities",
    components: {DayUnavailabilities}
})
export default class WeekUnavailabilities extends mixins(VModel) {
    capitalize = capitalize;

    @Prop(Boolean) readonly readonly !: boolean;
    @Prop(Boolean) readonly legend !: boolean;
    @Prop() directValue!: boolean;

    get days() {
        return moment.weekdays(true);
    }

    change() {
        this.$emit('change', this.innerValue)
    }
}
