var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"Tabs"},[_c('div',[_c('ul',{ref:"clickableLinksContainer",class:{
                linksContainer: true,
                round: _setup.props.mode === 'round',
                button: _setup.props.mode === 'button',
                underline: _setup.props.mode === 'underline',
            }},[_vm._l((_setup.elements),function(el,key){return _c('li',{class:{
                    navItem: true,
                    selected: el.selected,
                },on:{"click":function($event){return _setup.changeTab(key, $event)}}},[(el.leftIcon)?_c('i',{class:`icon ti ti-${el.leftIcon}`}):_vm._e(),_c('span',{staticClass:"text-xm semibold"},[_vm._v(_vm._s(el.label))]),(el.rightIcon)?_c('i',{class:`icon ti ti-${el.rightIcon}`}):_vm._e()])}),_c('div',{ref:"movingBackground",staticClass:"background"})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }