
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {ICandidateDB} from "@/types";
import Tabs from '@/components/ats/layout/Tabs.vue';
import ProfileIdentity from './ProfileIdentity.vue';
import ProfileJob from './ProfileJob.vue';
import ProfileAvailability from './ProfileAvailability.vue';
import ProfileTravel from './ProfileTravel.vue';
import ProfileWorkConditions from './ProfileWorkConditions.vue';
@Component({
    name: "Profile",
    components: {Tabs, ProfileIdentity, ProfileJob, ProfileAvailability, ProfileTravel, ProfileWorkConditions}
})
export default class Profile extends Vue {
    @Prop() candidate!: ICandidateDB;

    loading: boolean = false;
    routes: any = {};
    currentTabIndex = 0;

    components = [
        ProfileIdentity,
        ProfileJob,
        ProfileAvailability,
        ProfileTravel,
        ProfileWorkConditions,
    ]

    get currentTab() {
        return this.components[this.currentTabIndex];
    }

    routing() {
        const routes: any = this.$t('page.candidates.sub-navbar');

        return routes.map((r: any, index: number) => {
            const selected = index === this.currentTabIndex;

            return {
                ...r,
                selected,
            }
        });
    }

    changeTab(index: number) {
        this.routes[this.currentTabIndex].selected = false;
        this.routes[index].selected = true;
        this.currentTabIndex = index;
    }

    init() {
        this.loading = true;
        this.routes = this.routing();
        this.loading = false;
    }

    beforeMount() {
        this.init();
    }
}
