
import {Component, Prop} from 'vue-property-decorator';
import Card from "@/components/Card.vue"
import InlineField from "@/components/InlineField.vue"
import AskedField from '@/components/form/AskedField.vue';
import {mixins} from "vue-class-component";
import TitleManager from "@/mixins/TitleManager.vue";
import moment from "moment-timezone";
import { IWorkerHabilitation, StoreAction } from '@/types';
import ActionButton from '../ActionButton.vue';
import SelectAutocomplete from '../SelectAutocomplete.vue';
import { Action, Getter } from 'vuex-class';
import VModel from '@/mixins/VModel.vue';
import NewIcon from '../NewIcon.vue';
import AddHabilitationModal from '../modal/AddHabilitationModal.vue';
import {showModal} from "@/helpers/callables";
import { EWorkerHabilitationType } from '@/data';
import CandidateMixin from '@/mixins/CandidateMixin.vue';

@Component({
    name: "InterimAdministrativeDocuments",
    components: {AskedField, Card, InlineField, ActionButton, SelectAutocomplete, NewIcon, AddHabilitationModal}
})
export default class InterimAdministrativeDocuments extends mixins(VModel,  CandidateMixin) {

    // @Prop() administrativeDocuments!: {habilitations?: {title: string, expirationDate: number}[], BTPCard?: Date, medicalCheckup?: number, residencePermit?: number};
    @Getter('getHabilitations') getHabilitations!: any;
    moment = moment;
    error:any = {}
    loading = false;

    tmpModalDefaultType = "";
    modalOpen = false;

    @Action('actions/editInterim') editInterim!: StoreAction;
    @Prop(String) type!: string;

    get workerDocuments(): any {
        return Object.entries(this.innerValue.workerDocuments)
            .filter(doc => {
                return doc[0] !== EWorkerHabilitationType.DEGREE
            })
    }


    formatHabilitationDate(habilitation: IWorkerHabilitation) {
        return moment(habilitation.expirationDate).format( this.$t('date.format') as string );
    }

    isExpiredHabilitation(expirationDate: number) {
        return moment( moment().valueOf() ).isAfter( moment(expirationDate ));
    }

    async addHabilitation(document: IWorkerHabilitation) {
        if(document.expirationDate === 0 ||  isNaN(document.expirationDate as number)) {
            delete document["expirationDate"];
        }

        if(!this.innerValue.workerDocuments) this.innerValue.workerDocuments[this.tmpModalDefaultType] = [document]
        this.innerValue.workerDocuments[this.tmpModalDefaultType].push(document);

        await this.updateHabilitation();
    }

    deleteHabilitation(index: number, type: string) {
        this.innerValue.workerDocuments[type].splice(index, 1)

        this.updateHabilitation();
    }

    async updateHabilitation() {
        this.$emit('updateWorkerDocuments', this.innerValue);
        try {
            this.loading = true;
            if(this.type === 'interim') await this.editInterim(this.innerValue);
            if(this.type === 'candidate') await this.updateCandidate();
            this.$toast.open({
                message: <string>this.$t('page.profile.saved'),
                type: "success",
                duration: 2000
            });
            this.loading = false;
        } catch(e) {
            console.log(e);
            this.loading = false;
            this.$toast.open({
                message: <string>this.$t('global.serverError'),
                type: "error",
                duration: 5000
            });
        }
        this.$modal.hide('AddHabilitationModal');
    }

    displayAddHabilitationModal(type: EWorkerHabilitationType) {
        this.tmpModalDefaultType = type;
        this.modalOpen = true;
        showModal.call(this, 'AddHabilitationModal');
    }

    closeAddHabilitationModal() {
        this.modalOpen = false;
        this.tmpModalDefaultType = "";
    }

    beforeMount() {
    }
}
