
import {Component, Vue, Prop} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import CandidateModalCommandMixin from "@/components/ats/Candidate/CandidateModalCommandMixin.vue";


export default class ParsingOn extends mixins(CandidateModalCommandMixin) {
    name = "ParseWait";

}
