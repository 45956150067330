import { COLLECTIONS, ELogType } from "@/data";
import { capitalize, getFormattedHour } from "@/helpers/commons";
import i18n from "@/i18n";
import { IWorkerLogsContent, ILogDB, ILoggerLog, IInterview, IInterviewDB, ILoggerAgency } from "@/types";
import moment from "moment";

export default function useWorkerLogsFormater(logs: ILogDB<IWorkerLogsContent>[], worker: {firstname: string, lastname: string, interviews: IInterviewDB[]}, nbSelectedAgencies: number): ILoggerLog[] {
    const candidateName = `${worker.firstname} ${worker.lastname}`;

    return logs.map(log => {
        const actionDoneBy = nbSelectedAgencies > 1 ?
            i18n.t('page.candidate.agencyActionDoneBy', {agencyName: log.agency.name}).toString() : '';

        let formatedLog: ILoggerLog = {
            _id: log._id.toString(),
            timestamp: log.timestamp,
            title: i18n.t(
                `page.candidate.logs.${log.type}.text`,
                {
                    collaborator: log?.collaborator?.name || "L'agence " + log.agency.name,
                    candidate: candidateName,

                }
            ) as string,
            icon: i18n.t(`page.candidate.logs.${log.type}.icon`) as string,
            commentary: actionDoneBy
        }

        if (log.type === ELogType.CREATE) {
            formatedLog.title = i18n.t(
                `page.candidate.logs.${log.type}.${log.collectionOrigin}.text`,
                {
                    collaborator: log?.collaborator?.name || "L'agence " + log.agency.name,
                    candidate: candidateName
                }
            ) as string;
        }

        if (log.type === ELogType.ADD_AGENCY) {
            const agencies = log.content.originAgencies?.map((agency: ILoggerAgency) => agency.name).join(', ');

            formatedLog.title = i18n.t(
                `page.candidate.logs.${log.type}.${log.collectionOrigin}.text`,
                {
                    collaborator: log?.collaborator?.name || "L'agence " + log.agency.name,
                    candidate: candidateName,
                    agencies: agencies
                }
            ) as string;
        }

        if (log.type === ELogType.IMPORT_EVOLIA) {
            formatedLog.title = i18n.t(
                `page.candidate.logs.${log.type}.${log.collectionOrigin}.text`,
                {
                    collaborator: log?.collaborator?.name || "L'agence " + log.agency.name,
                    candidate: candidateName
                }
            ) as string;
        }

        if (log.type === ELogType.CREATE_FROM_INTERIM) {
            const agencies = log.content.originAgencies?.map((agency: ILoggerAgency) => agency.name).join(', ');

            formatedLog.title = i18n.t(
                `page.candidate.logs.${log.type}.text`,
                {
                    collaborator: log?.collaborator?.name || "L'agence " + log.agency.name,
                    candidate: capitalize(candidateName),
                    agencies: agencies
                }
            ) as string
        }

        if(log.type === ELogType.NOTE) {
            formatedLog.description = [{type: 'textarea', editable: true, text: log.content.internalComment || ""}];
        }

        if(log.type === ELogType.CONTACT) {
            formatedLog.title = i18n.t(
                `page.candidate.logs.${log.type}.sub-type.${log.content.contactType}.text`,
                {collaborator: log.collaborator.name, candidate: candidateName}
            ) as string;
            formatedLog.icon = i18n.t(`page.candidate.logs.${log.type}.sub-type.${log.content.contactType}.icon`) as string;
            if(log?.content?.internalComment) {
                formatedLog.description = [{
                    type: 'textarea', editable: true, text: log.content.internalComment || ""
                }]
            }
        }

        if(log.type === ELogType.INTERVIEW) {
            const itw = worker.interviews.find(itw => itw._id.toString() === log.content.interviewId?.toString());
            formatedLog.description = [
                {
                    type: 'block',
                    editable: true,
                    text: i18n.t(
                        `page.candidate.logs.${log.type}.subtitle`,
                        {date: moment(itw?.date).format('DD/MM/YYYY'), startHour: getFormattedHour(itw?.start || 0), endHour: getFormattedHour(itw?.end || 0), collaborator: log.collaborator.name}
                    ) as string,
                },
            ]
            if(log.content?.internalComment) {
                formatedLog.description?.push({type: 'textarea', editable: true, text: log.content.internalComment});
            }
        }

        if(log.type === ELogType.CHANGE_STATUS) {
            formatedLog.title = i18n.t(
                `page.candidate.logs.${log.type}.sub-type.${log.content.statusAfter}.text`,
                {
                    collaborator: log.collaborator.name,
                    candidate: candidateName,
                }
            ) as string;
            formatedLog.icon = i18n.t(`page.candidate.logs.${log.type}.sub-type.${log.content.statusAfter}.icon`) as string;
            if(log.content?.internalComment) {
                formatedLog.description = [{type: 'textarea', editable: true, text: log.content.internalComment}];
            }
        }

        if(log.type === ELogType.FEEDBACK) {
            if(log.content?.internalComment) {
                formatedLog.description = [{type: 'textarea', editable: true, text: log.content.internalComment}];
            }
        }

        return formatedLog;
    });
}
