
import {Component, Prop,} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import { Action } from 'vuex-class';
import { ICandidateDB, ObjectID, StoreAction } from '@/types';
import VModel from './VModel.vue';
import moment from "moment";

@Component({
    name: "CandidateMixin"
})
export default class CandidateMixin extends mixins(VModel) {
    @Prop({
        required: true,
    }) candidate!: ICandidateDB | any;

    @Action('actions/getCandidate') getCandidate!: StoreAction;
    @Action('actions/addCandidateToJobOffer') addCandidateToJobOffer!: StoreAction;
    @Action('actions/editCandidate') saveCandidate!: StoreAction;
    @Action('actions/loadJobOffers') loadJobOffers!: StoreAction;

    errors: any = {}

    async reloadCandidate() {
        this.$emit('forceRender');
    }

    async updateCandidate() {
        this.errors = {};
        try {
            const preparedCandidate: ICandidateDB = {
                ...this.candidate,
                birthDate: this.candidate.birthDate ? moment(this.candidate.birthDate, this.$t('date.format') as string).valueOf() : null,
            }

            await this.saveCandidate(preparedCandidate);
            this.$toast.open({
                message: <string>this.$t('page.profile.saved'),
                type: "success",
                duration: 2000
            });
        } catch(e) {
            this.errors = e.errors;
            console.log(e);
            this.$toast.open({
                message: <string>this.$t('global.serverError'),
                type: "error",
                duration: 5000
            });
        }
    }

    async getNotApplyJobOffers(search = "") {
        const rawJobOffers = await this.loadJobOffers({
            search: search,
            notThisCandidate: this.candidate._id,
        });

        return rawJobOffers.jobOffers;
    }

    async associateCandidateToJobOffer(jobOfferId: ObjectID) {
        await this.addCandidateToJobOffer({
            _id: jobOfferId,
            data: {
                candidateId: this.candidate._id,
            }
        });
        this.reloadCandidate();
    }
}
