
import { Component } from "vue-property-decorator";
import { IJobOffer, IJobOfferDB } from "@/types";
import Tabs from "@/components/ats/layout/Tabs.vue";
import PdfManager from "@/components/PdfManager.vue";
import Card from "@/components/Card.vue";
import { shortenParagraph } from "@/helpers/commons";
import { ROUTES } from "@/data";
import Tag from "@/components/Tag.vue";
import Search from "@/components/Search.vue";
import { mixins } from "vue-class-component";
import CandidateMixin from "@/mixins/CandidateMixin.vue";
import NewIcon from "@/components/NewIcon.vue";
import moment from "moment-timezone";
import { Getter } from "vuex-class";

@Component({
    name: "JobApplication",
    components: { NewIcon, Tabs, PdfManager, Card, Tag, Search },
})
export default class JobApplication extends mixins(CandidateMixin) {
    @Getter("getJob") getJob!: any;

    jobOffers: Array<IJobOfferDB> = [];

    get getJobOffers() {
        return (
            this.jobOffers.map((j) => {
                return {
                    title: j.name,
                    tags: [{ icon: "mapMarker", name: j.address.city }],
                    action: { event: "add", tanslation: this.$t("global.add") },
                };
            }) || []
        );
    }

    addJob(index: number) {
        this.associateCandidateToJobOffer(this.jobOffers[index]._id as string);
    }

    description(index: number) {
        return shortenParagraph(this.candidate?.jobOffers?.[index].description, 100);
    }

    leftCol = {
        items: [
            { label: "CV", selected: true },
            { label: "Profil", selected: false },
        ],
        currentIndex: 0,
    };

    rightCol = {
        items: [
            { label: "Offres", selected: true },
            { label: "Commandes", selected: false },
        ],
        currentIndex: 0,
    };

    jobOfferDetails(jobOffer: any) {
        if (jobOffer?.client?.name && jobOffer.address.city) return jobOffer.client.name + " - " + jobOffer.address.city;
        if (!jobOffer?.client?.name && jobOffer.address.city) return jobOffer.address.city;
    }

    changeLeftTab(index: number) {
        this.leftCol.items[this.leftCol.currentIndex].selected = false;
        this.leftCol.items[index].selected = true;
        this.leftCol.currentIndex = index;
    }

    changeRightTab(index: number) {
        this.rightCol.items[this.rightCol.currentIndex].selected = false;
        this.rightCol.items[index].selected = true;
        this.rightCol.currentIndex = index;
    }

    goToJobOffer(jobOfferId: string) {
        this.$router.push({ name: ROUTES.ATS.JOBOFFER.JOB, params: { jobOfferId } });
    }

    formatDate(data: string) {
        return moment(data).format("DD/MM/YY");
    }

    loading: boolean = false;

    async beforeMount() {
        await this.searchJobOffers();
    }

    async searchJobOffers(search: string = "") {
        console.log(search);

        this.jobOffers = await this.getNotApplyJobOffers(search);
    }
}
