
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { ICandidateDB, LabelValue, IWorkerJob, IJobDB, StoreAction, IHabilitation } from "@/types";
import Tabs from "@/components/ats/layout/Tabs.vue";
import PdfManager from "@/components/PdfManager.vue";
import SelectAutocomplete from "@/components/SelectAutocomplete.vue";
import clonedeep from "lodash.clonedeep";
import { Getter, Action } from "vuex-class";
import { mixins } from "vue-class-component";
import { NaNOrValue } from "@/helpers/commons";
import VModel from "@/mixins/VModel.vue";
import CandidateMixin from "@/mixins/CandidateMixin.vue";
import InlineField from "@/components/InlineField.vue";
import ActionButton from "@/components/ActionButton.vue";
import Field from "@/components/form/Field.vue";
import moment from "moment-timezone";
import NewIcon from "@/components/NewIcon.vue";
import InterimAdministrativeDocuments from "@/components/interim/InterimAdministrativeDocuments.vue";

@Component({
    name: "ProfileJob",
    components: {
        Tabs,
        PdfManager,
        SelectAutocomplete,
        VModel,
        InlineField,
        ActionButton,
        Field,
        NewIcon,
        InterimAdministrativeDocuments,
    },
})
export default class ProfileJob extends mixins(CandidateMixin) {
    loading: boolean = false;
    customFields: any = null;
    customJobs: Array<IWorkerJob> = [];
    customKeywords: Array<any> = [];
    NaNOrValue = NaNOrValue;
    habilitation: any = {
        select: null,
        expirationDate: "",
        idHabilitation: "",
        habilitationName: "",
    };

    @Action("actions/editCandidate") editCandidate!: StoreAction;
    @Getter("jobOptions") jobOptions!: LabelValue[];
    @Getter("getJob") getJob!: any;
    @Getter("getHabilitations") getHabilitations!: any;

    mounted() {
        this.$watch("customJobs", () => {
            this.customFields = clonedeep(this.candidate);
            this.updateCandidate();
        }),
            this.$watch("customKeywords", () => {
                this.customFields = clonedeep(this.candidate);
                this.udpatedkeywords();
            });
    }

    get docs(): any {
        return Object.entries(this.candidate.workerDocuments);
    }

    async updateCandidate() {
        try {
            this.candidate.jobs = this.customJobs;

            if (this.candidate.jobs?.length) {
                this.candidate.jobs.forEach((job: any) => {
                    job.jobId = job.value;
                    if (job.certifications) job.certifications = job.certifications.map(this.NaNOrValue);
                    else job.certifications = [];
                });
            }
            const preparedCandidate: ICandidateDB = {
                ...this.candidate,
                birthDate: this.candidate.birthDate
                    ? moment(this.candidate.birthDate, this.$t("date.format") as string).valueOf()
                    : null,
            };
            await this.editCandidate(preparedCandidate);
            this.$toast.open({
                message: <string>this.$t("page.profile.saved"),
                type: "success",
                duration: 2000,
            });
        } catch (e) {
            console.log(e);
            this.$toast.open({
                message: <string>this.$t("global.serverError"),
                type: "error",
                duration: 3000,
            });
        }
    }

    async udpatedkeywords() {
        try {
            this.candidate.keywords = this.customKeywords;

            if (this.candidate.keywords?.length) {
                const format = this.getLabels(this.candidate.keywords);
                this.candidate.keywords = format;
            }

            await this.editCandidate(this.candidate);
            this.$toast.open({
                message: <string>this.$t("page.profile.saved"),
                type: "success",
                duration: 2000,
            });
        } catch (e) {
            console.log(e);
            this.$toast.open({
                message: <string>this.$t("global.serverError"),
                type: "error",
                duration: 3000,
            });
        }
    }

    getJobValues(workerJob: any) {
        const job: IJobDB = this.getJob(workerJob.jobId);

        return {
            label: job.name || workerJob.value,
            slug: job.slugs || [workerJob.value],
            value: job._id || workerJob.value,
        };
    }

    getLabels(keywords: any) {
        const labelsArray = [];
        for (let i = 0; i < keywords.length; i++) {
            const label = keywords[i].label;
            if (label !== undefined) {
                labelsArray.push(label);
            }
        }
        return labelsArray;
    }

    formatJobs() {
        const jobs = this.customFields.jobs.value || this.customFields.jobs;
        return jobs.map(this.getJobValues) || [];
    }
    formatKeyword() {
        return this.customFields.keywords.map((k: string) => {
            return { label: k };
        });
    }

    initFields() {
        if (this.customFields.jobs) {
            this.customJobs = this.formatJobs();
        }

        if (this.customFields.keywords) {
            this.customKeywords = this.formatKeyword();
        }
    }

    getExpirationDate(habilitationDate: number) {
        return moment(habilitationDate).format("DD/MM/YYYY");
    }

    // async addHabilitation () {
    //     this.candidate.habilitations.push({
    //         idHabilitation: this.habilitation.select[0].value,
    //         expirationDate: moment(this.habilitation.expirationDate, 'DD MM YYYY').valueOf(),
    //         habilitationName: this.habilitation.select[0].label,
    //     });

    //     await this.updateCandidate();
    //     this.habilitation.idHabilitation = '';
    //     this.habilitation.expirationDate = '';
    //     this.habilitation.habilitationName = '';
    //     this.habilitation.select = null;

    //     this.$forceUpdate()
    // }

    // deleteHabilitation(index: number) {
    //     this.candidate.habilitations.splice(index, 1);
    //     this.updateCandidate();
    // }

    @Watch("habilitation", { deep: true })
    isSetHabilitationAndExpirationDate() {
        return this.habilitation.expirationDate !== "" && this.habilitation.select.length > 0;
    }

    beforeMount() {
        this.customFields = clonedeep(this.candidate);
        this.initFields();
    }
}
