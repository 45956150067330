
import {Component} from 'vue-property-decorator';
import {TeamPreference} from "@/data";
import RoundRadio from "@/components/RoundRadio.vue";
import { mixins } from 'vue-class-component';
import CandidateMixin from '@/mixins/CandidateMixin.vue';

@Component({
    name: "ProfileWorkConditions",
    components: { RoundRadio}
})
export default class ProfileWorkConditions extends mixins(CandidateMixin) {
    loading: boolean = false;

    getTeamPreference(teamPreference: TeamPreference) {
        if (this.candidate.teamPreference === teamPreference) {
            return true;
        }
        return false;
    }

    getWorkConditions(environmentKey: string) {
        return this.$t(`page.candidate.workConditions.environment.${environmentKey}`);
    }

    getSchedule(schedule: string) {
        return this.$t(`page.candidate.workConditions.schedulePreference.${schedule}`);
    }

    getContractType(contract: string) {
        return this.$t(`page.candidate.workConditions.contractType.${contract}`);
    }

    toggleTeamPreference(teamPreference: TeamPreference) {
        this.$set(this.candidate, 'teamPreference', teamPreference);

        this.updateCandidate();
    }

    onChangeEnvrionment(condition: string, value: boolean) {
        // @ts-ignore
        this.candidate.environment[condition] = !value;

        this.updateCandidate();
    }

    onChangeSchedule(schedule: string, value: boolean) {
        // @ts-ignore
        this.candidate.schedule[schedule] = !value;

        this.updateCandidate();
    }

    onChangeContractPreference(contract: string, value: boolean) {
        this.candidate.contractPreference[contract] = !value;

        this.updateCandidate();
    }

}
