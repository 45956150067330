
    import {Component, Vue, Prop} from 'vue-property-decorator';
    import InputGmapAutocomplete from "@/components/form/inputGmapAutocomplete.vue";
    import {LabelValue} from "@/types";

    @Component({
        name: "InlineField",
        components: {InputGmapAutocomplete}
    })
    export default class InlineField extends Vue {
        $refs!: {
            read:any
        }
        @Prop(String) readonly label!: string;
        @Prop() readonly value!: any;
        @Prop(Boolean) readonly readonly!: boolean;
        @Prop(Boolean) readonly editonly!: boolean;
        @Prop(Boolean) readonly copy!: boolean;
        @Prop() readonly editCallback!: () => void;
        @Prop(Boolean) readonly gmap!: boolean;
        @Prop() readonly options!: LabelValue[];
        @Prop() readonly type!: string;
        @Prop() readonly labelClick!:any;
        @Prop() readonly valueClick!:any;
        @Prop() readonly icon!:string;
        @Prop() readonly iconClass!:string;

        mode: string = "read";

        get isRead() {
            return this.mode === 'read';
        }

        get isEdit() {
            return this.mode === 'edit';
        }

        setReadMode() {
            this.mode = 'read';
        }

        setEditMode() {
            if (this.editCallback) {
                this.editCallback();
            } else {
                this.mode = 'edit';
            }
        }
        defaultClick() {

        }

        input(n: string) {
            this.$emit('input', n);
        }

        select() {
            const range: any = document.createRange();
            range.selectNode(this.$refs.read.$el.querySelector('.value'));
            //@ts-ignore;
            window.getSelection().removeAllRanges();
            //@ts-ignore;
            window.getSelection().addRange(range);
            document.execCommand("copy")
        }

        beforeMount() {
            if (this.editonly) {
                this.mode = "edit";
            }
        }
    }
