
import {Component, Vue, Prop} from 'vue-property-decorator';

@Component({
    name: "AskedField"
})
export default class AskedField extends Vue {
    @Prop(String) label!: string
    @Prop(String) askedField!: string;
    @Prop() hasChanged!: any;

    isOpen = false;

    openChanges() {
        this.isOpen = true;
    }

    async validateChanges(action: string) {
        this.$emit('updateAskedChange', action);
        this.isOpen = false;
    }
}
