
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {ICandidateDB} from "@/types";
import Tabs from '@/components/ats/layout/Tabs.vue';
import Card from '@/components/Card.vue';
import { Action, Getter } from 'vuex-class';
import { StoreAction, IRegularAvailabilities } from '@/types';
import WeekUnavailabilities from '@/components/WeekUnavailabilities.vue';
import { mixins } from 'vue-class-component';
import CandidateMixin from '@/mixins/CandidateMixin.vue';

@Component({
    name: "ProfileAvailability",
    components: {Tabs, Card, WeekUnavailabilities}
})
export default class ProfileAvailability extends mixins(CandidateMixin) {
    loading: boolean = false;
    showLongUnav = false;
    innerLoading = false;

    async availabilitiesChanging(content: any) {
        this.candidate.regularAvailabilities = content;
        await this.updateCandidate();
    }

    async updateNightUnav(canWorkAtNight: boolean) {
        this.candidate.nightShift = canWorkAtNight;
        this.candidate.regularAvailabilities = this.candidate.regularAvailabilities.map((regularAvailability: IRegularAvailabilities, index: number) => {
            const availability = regularAvailability;
            availability.night = canWorkAtNight;
            if(!this.candidate.weekendShift && (index === 5 || index === 6)) {
                availability.night = false
            }
            return availability;
        });
        await this.updateCandidate();
    }

    async updateWeekendUnav(canWorkOnWeekends: boolean) {
        this.candidate.regularAvailabilities = this.candidate.regularAvailabilities.map((regularAvailability: IRegularAvailabilities, index: number) => {
            const availability = regularAvailability;
            this.candidate.weekendShift = canWorkOnWeekends;
            if(index === 5 || index === 6) {
                availability.morning = canWorkOnWeekends;
                availability.afternoon = canWorkOnWeekends;
                availability.evening = canWorkOnWeekends;
                if(this.candidate.nightShift) {
                    availability.night = canWorkOnWeekends;
                }
            }
            return availability;
        });
        await this.updateCandidate();
    }

}
