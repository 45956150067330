
import {Component} from 'vue-property-decorator';
import Tabs from '@/components/ats/layout/Tabs.vue';
import VModel from "@/mixins/VModel.vue";
import {mixins} from "vue-class-component";
import Card from '@/components/Card.vue';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';
import InputGmapAutocomplete from "@/components/form/inputGmapAutocomplete.vue";
import {formattedAddress,pluralize} from "@/helpers/commons";
import { IAddress } from '@/types';
import CandidateMixin from '@/mixins/CandidateMixin.vue';
@Component({
    name: "ProfileTravel",
    components: {VModel, Tabs, Card, VueSlider, InputGmapAutocomplete}
})

export default class ProfileTravel extends mixins(CandidateMixin) {
    travelInformations: any = {
        defaultType: "distance",
        possibleValues: [{label: "Distance", value: "distance"}, {label: "Temps", value: "time"},],
        distance: {
            asked : "Quelle est la distance maximale que vous souhaitez faire entre votre domicile et votre lieu de travail ?",
            unit: "km",
            value: parseInt(this.candidate.maxTravelDistance),
        },
        time: {
            asked : "Quelle est le temps maximal que vous souhaitez faire entre votre domicile et votre lieu de travail ?",
            unit: "min",
            value: parseInt(this.candidate.maxTravelTime),
        },
    }

    addressValue(address: IAddress) {
        return formattedAddress(address);
    }

    async placeChanged(address: IAddress, place: any) {
        this.candidate.address.street = [place.street_number, place.street].filter(s => s).join(' ');
        this.candidate.address.complement = place.complement;
        this.candidate.address.city = place.locality;
        this.candidate.address.zip = place.postal_code;
        this.candidate.address.countryCode = place.countryCode;
        this.candidate.address.location = [place.longitude, place.latitude];
        this.updateCandidate();
    }

    async saveTravelInfirmations() {
        if(this.travelInformations.defaultType === 'distance') this.candidate.maxTravelDistance = this.travelInformations[this.travelInformations.defaultType].value;
        if(this.travelInformations.defaultType === 'time') this.candidate.maxTravelTime = this.travelInformations[this.travelInformations.defaultType].value;
        await this.updateCandidate();
    }

    get formattedTooltip(){
        const value = this.travelInformations[this.travelInformations.defaultType].value;
        const type = this.travelInformations.defaultType;
        const formatedStr = value + (type === 'distance' ? 'km' : pluralize('min',value));

        return formatedStr;
    }

}
