
import {Component, Prop, Watch} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ModalMixin from "@/components/modal/ModalMixin.vue";
import {Getter} from "vuex-class";
import { EWorkerHabilitationType } from '@/data';
import { IWorkerHabilitation, LabelValue } from '@/types';
import ActionButton from '../ActionButton.vue';
import moment from 'moment';
import SelectAutocomplete from '../SelectAutocomplete.vue';

    @Component({
        name: "AddHabilitationModal",
        components: {ActionButton, SelectAutocomplete}
    })

    export default class AddHabilitationModal extends mixins(ModalMixin) {
        name = "AddHabilitationModal";

        @Prop() defaultHabilitationType: any;

        @Getter('getWorkerDocuments') getWorkerDocuments!: any;

        document: any = {
            expirationDate: "",
            idDocument: "",
            acquisitionDate: "",
        };

        @Watch('document.idDocument')
        onChangeDocumentType() {
            this.displayExpirationDateField();
            this.displayObtentionDateField();

            const hasEvoliaExpirationDate = this.getWorkerDocuments[this.defaultHabilitationType].find((doc: any) => this.document.idDocument.toString() === doc.value.toString());
            if(!hasEvoliaExpirationDate || hasEvoliaExpirationDate.duration === false) {
                this.document.acquisitionDate = "";
                this.document.expirationDate = "";
            }

            this.computeExpirationDate();
        }

        get completedForm(): boolean {
            if(this.document.idDocument.length === 0 || this.document.expirationDate.length < 10) return false;

            return true;
        }

        displayExpirationDateField(): boolean {
            return true;
        }

        displayObtentionDateField(): boolean {
            if(this.defaultHabilitationType === EWorkerHabilitationType.MEDICAL_CHECK_UP) return false;

            return this.getWorkerDocuments[this.defaultHabilitationType]
                .find((doc: any) => this.document.idDocument.toString() === doc.value.toString())?.duration
        }

        get habilitationsByType(): LabelValue[] {
            return this.getWorkerDocuments[this.defaultHabilitationType]
                .map((h: any) => {
                    return {label: h.label, value: h.value}
                })
                .sort((a: any, b: any) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
        }

        get modalTitle() {
            return this.$t('modals.addSomething.title',
            {
                toAdd: (this.$t('page.interim.administrativeDocuments.' + this.defaultHabilitationType) as string).toLocaleLowerCase(),
            })
        }

        cleanHabilitation() {
            this.document = {
                expirationDate: "",
                idDocument: "",
                acquisitionDate: "",
            };
        }

        cancel() {
            this.cleanHabilitation();
            this.$emit("closed");
            this.$modal.hide(this.name);
        }

        validate(callback?: any) {
            const docToSend = {
                type: this.defaultHabilitationType,
                idDocument: this.document.idDocument,
                acquisitionDate: moment(this.document.acquisitionDate, 'DD/MM/YYYY').valueOf(),
                expirationDate: moment(this.document.expirationDate, 'DD/MM/YYYY').valueOf(),
                documentName: this.getWorkerDocuments[this.defaultHabilitationType].find((h: LabelValue) => h.value === this.document.idDocument).label,
            }

            this.$emit("submit", docToSend, callback);
            this.cleanHabilitation();
        }

        changeDocument(document: any) {
            if (document) {
                this.document.idDocument = document[0].value
            }
        }

        computeExpirationDate() {
            const docExists = this.getWorkerDocuments[this.defaultHabilitationType].find((doc: any) => this.document.idDocument.toString() === doc.value.toString());

            if(!docExists || docExists.duration === false) return;
            if(this.document.acquisitionDate.length < 10) {
                this.document.expirationDate = "";
                return;
            };

            this.document.expirationDate =
                moment(this.document.acquisitionDate, 'DD/MM/YYYY')
                .add( docExists.duration, 'months')
                .format('DD/MM/YYYY');
        }

        beforeMount() {
        }
    }

