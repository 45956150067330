
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import Tag from '@/components/Tag.vue';

@Component({
    name: "Search",
    components: {Tag}
})
export default class Search extends Vue {
    @Prop() resultList!: any;
    search = "";
    displaySearchResults = false;
    hideTimeout: any;

    @Watch('search')
    autoHideSearchResults() {
        this.displaySearchResults = true;
        if(this.hideTimeout) clearTimeout(this.hideTimeout);

        this.hideTimeout = setTimeout(() => {
            this.displaySearchResults = false;
        }, 5000);
    }

    onAction(eventName: string, index: number) {
        this.autoHideSearchResults();
        this.$emit(eventName, index);
    }

    onWrite() {
        this.autoHideSearchResults();
        this.$emit('write', this.search);
    }

    closeResults() {
        this.displaySearchResults = false;
    }

}
